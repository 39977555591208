import React from "react"
import Header from '../components/headnav';
import Footer from '../components/footer';
import style from './content.module.scss';

export default ({ children }) => (
	<div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
		<Header />
		<div className = { style.content }>
			{children}
		</div>
		<Footer />
	</div>
)