import React from "react";
import ContactLayout from '../layout/content';
import useSiteMetaData from '../hooks/siteMetaData';

export default () => {
    const metaData = useSiteMetaData();
    return <ContactLayout>
        <h1>Contact</h1>
        <p>If you want to get in touch you can fill out the form below or send an email to <a href={`mailto:${metaData.email}`}>{metaData.email}</a></p>
        <p>Any feedback is welcome. I'd love to hear any ways that you think this site could be improved or something that you liked about it. </p>
        <form className = 'flex flex-column items-start' name="contact" method = 'POST' data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <label htmlFor='name'>
                Name
            </label>
            <input className = 'mt2 mb3' id='name' type='text' name='name' required/>
            <label htmlFor='email'>
                Email
            </label>
            <input className='mt2 mb3' id='email' type='email' name='email' required/>
            <label htmlFor='message'>
                Message
            </label>
            <textarea className='mt2 mb3 w-100' id='message' name='message' resize='false' />
            <button className = "w4 self-end">Submit</button>

        </form>
    </ContactLayout>
}